import AnnotationContainer from '@eg/elements/AnnotationContainer';
import AnnotationProvider from '@eg/elements/AnnotationProvider';
import Button from '@eg/elements/Button';
import { CopyLinkIcon } from '@eg/elements/components/Icons';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { DateInputValue } from '@eg/elements/DateInput';
import DateInputRow from '@eg/elements/DateInputRow';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import InputRow from '@eg/elements/InputRow';
import MessageBox from '@eg/elements/MessageBox';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { DateErrors } from '@eg/elements/utils/validation/date';
import { Field, FieldProps, FormikProps, FormikValues, getIn } from 'formik';
import {
    Addresses,
    Adresse,
    isValidIsoDateFormat,
    mapDateInputToIsoDateString,
    mapIsoDateStringToDateInput,
    Person
} from 'kfo-common';
import * as React from 'react';
import { getAddressesFromStringValue } from '../../helpers/addressesHelper';
import { getErrorMessage } from '../../helpers/validationHelpers';
import { trackElementClickImmediate } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';
import {
    cityErrorMessage,
    emailErrorMessage,
    housenumberErrorMessage,
    phonenumberErrorMessage,
    phonenumberErrorMessageTwo,
    postalCodeErrorMessage,
    streetErrorMessage
} from '../../validation/PersonalDataSchema';

import './PersonalData.css';

enum DataIdInsuredPersonPage {
    INPUT_BIRTHDATE = 'DataIdInsuredPersonPage-Input-Birthdate',
    INPUT_SALUTATION_MALE = 'DataIdInsuredPersonPage-Input-Salutation-Male',
    INPUT_SALUTATION_FEMALE = 'DataIdInsuredPersonPage-Input-Salutation-Female',
    INPUT_FIRSTNAME = 'DataIdInsuredPersonPage-Input-Firstname',
    INPUT_SURNAME = 'DataIdInsuredPersonPage-Input-Surname',
    INPUT_STREET = 'DataIdInsuredPersonPage-Input-Street',
    INPUT_HOUSENUMBER = 'DataIdInsuredPersonPage-Input-Housenumber',
    INPUT_CITY = 'DataIdInsuredPersonPage-Input-City',
    INPUT_POSTALCODE = 'DataIdInsuredPersonPage-Input-Postalcode',
    INPUT_EMAIL = 'DataIdInsuredPersonPage-Input-Email',
    INPUT_PREFIX = 'DataIdInsuredPersonPage-Input-Prefix',
    INPUT_PHONENUMBER = 'DataIdInsuredPersonPage-Input-Phonenumber'
}

const DREI = 3;
const ZWEI = 2;
const DREI_ZU_ZWEI_COLSPAN = [DREI, ZWEI];
const ZWEI_ZU_DREI_COLSPAN = [ZWEI, DREI];

type UpdatePersonCallback = (values: Partial<Person>, callback?: () => void) => void;

export interface PersonalDataProps {
    form: FormikProps<Person>;
    inputData: Person;
    updateCallback: UpdatePersonCallback;
    detailedMode: boolean;
    disableBirthdate: boolean;
    disableInfoBoxEudsgvo?: boolean;
    policyHolderAddress?: Adresse;
    showUsePolicyHolderData?: boolean;
    spcsEmailError?: boolean;
    spcsPhoneError?: boolean;
    spcsAddressError?: boolean;
}

const PersonalData = (props: PersonalDataProps): JSX.Element => {
    return (<div className="PersonalData">
            {!props.disableInfoBoxEudsgvo && <InfoBoxEudsgvo/>}
            {renderSalutation(props)}
            {renderFirstname(props)}
            {renderSurname(props)}
            <br/>
            {props.showUsePolicyHolderData && renderUsePolicyHolderDataLink(props)}
            {renderStreetHousenumber(props)}
            {renderPostalCity(props)}
            <br/>
            {renderBirthdate(props)}
            <br/>
            {props.detailedMode && <AnnotationProvider>
                {renderEmail(props)}
                {renderTelephoneNumber(props)}
                <AnnotationContainer/>
            </AnnotationProvider>}
        </div>
    );
};

const InfoBoxEudsgvo = () => {
    return (
        <MessageBox
            data-test-id="info_eudsgvo"
            children={
                <div>
                    Bei der Verarbeitung von personenbezogenen Daten beachtet ERGO die Vorschriften der
                    EU-Datenschutz-Grundverordnung. Ausführliche Informationen finden Sie in unserem
                    Datenschutz-Bereich. Bei Anforderung eines Angebots erhalten Sie die Informationen in Ihren
                    Vertragsunterlagen.
                </div>
            }
        />
    );
};

const renderUsePolicyHolderDataLink = (props: PersonalDataProps): JSX.Element => {
    return (
        <div style={{display: 'flex', alignItems: 'center', lineHeight: '24px', cursor: 'pointer'}}>
            <Button
                type="button"
                data-component-id="copy-policy-holder-address"
                variant="text-link"
                iconLeft={CopyLinkIcon}
                onClick={() => {
                    trackElementClickImmediate(TrackingElement.Link_DatenVersicherungsnehmer);
                    const address = props.policyHolderAddress;
                    if (address) {
                        if (address.strasse) {
                            props.form.setFieldValue('adresse.strasse', address.strasse);
                            props.form.setFieldTouched('adresse.strasse');
                        }
                        if (address.hausnummer) {
                            props.form.setFieldValue('adresse.hausnummer', address.hausnummer);
                            props.form.setFieldTouched('adresse.hausnummer');
                        }
                        if (address.plz) {
                            props.form.setFieldValue('adresse.plz', address.plz);
                            props.form.setFieldTouched('adresse.plz');
                        }
                        if (address.ort) {
                            props.form.setFieldValue('adresse.ort', address.ort);
                            props.form.setFieldTouched('adresse.ort');
                        }
                    }
                }}>
                Adresse des Versicherungsnehmers übernehmen
            </Button>
        </div>);
};

const renderSalutation = (props: PersonalDataProps): JSX.Element => {
    return (<Field
        name="anrede"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<RadioGroupRow
                label="Anrede"
                id={field.name}
                name={field.name}
                onBlur={() => setFieldTouched(field.name)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        anrede: getAddressesFromStringValue(event.target.value)
                    });
                }}
                error={getErrorMessage(props.form, field)}
                value={field.value}
            >
                <Radio
                    value={Addresses.MR}
                    label="Herr"
                    data-component-id={DataIdInsuredPersonPage.INPUT_SALUTATION_MALE}
                />
                <Radio
                    value={Addresses.MS}
                    label="Frau"
                    data-component-id={DataIdInsuredPersonPage.INPUT_SALUTATION_FEMALE}
                />
            </RadioGroupRow>);
        }}>
    </Field>);
};

const renderFirstname = (props: PersonalDataProps): JSX.Element => {
    const maxLengthFirstname = 31;
    return (<Field
        name="vorname"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="Vorname"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_FIRSTNAME}
                maxLength={maxLengthFirstname}
                placeholder="Vorname"
                onBlur={() => setFieldTouched(field.name)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        vorname: event.target.value
                    });
                }}
                value={field.value}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderSurname = (props: PersonalDataProps): JSX.Element => {
    const maxLengthSurname = 36;
    return (<Field
        name="nachname"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="Nachname"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_SURNAME}
                maxLength={maxLengthSurname}
                placeholder="Nachname"
                onBlur={() => setFieldTouched(field.name)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        nachname: event.target.value
                    });
                }}
                value={field.value}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderBirthdate = (props: PersonalDataProps): JSX.Element => {
    const fullYearHelper = 100;
    const minDate = new Date();
    minDate.setFullYear(new Date().getFullYear() - fullYearHelper);
    const maxDate = new Date();
    return (<Field
            name="birthdateField"
            render={({field}: FieldProps<FormikValues>) => {
                const {setFieldValue, setFieldTouched} = props.form;
                return (<DateInputRow
                    label="Geburtsdatum"
                    id={field.name}
                    autoTab={true}
                    data-component-id={DataIdInsuredPersonPage.INPUT_BIRTHDATE}
                    minDate={minDate}
                    maxDate={maxDate}
                    onBlur={() => {
                        setFieldTouched('birthdate');
                        setFieldTouched(field.name);
                    }}
                    disabled={props.disableBirthdate}
                    onChange={(dateObject: DateInputValue, componentError: DateErrors) => {
                        if (!componentError.valid) {
                            setFieldValue('dateError', componentError.badInput || componentError.rangeOverflow ||
                            componentError.rangeUnderflow || componentError.valueMissing);
                        }
                        else {
                           setFieldValue('dateError', undefined);
                        }
                        setFieldValue('birthdate', mapDateInputToIsoDateString(dateObject));
                        setFieldValue(field.name, dateObject);
                        props.updateCallback({
                            birthdate: mapDateInputToIsoDateString(dateObject)
                        });
                    }}
                    value={(field.value && !isValidIsoDateFormat(field.value)) ? field.value : mapIsoDateStringToDateInput(props.inputData.birthdate)}
                    error={getIn(props.form.values, 'dateError') || getErrorMessage(props.form, {name: 'birthdate'})}
                />);
            }}/>
    );
};

const renderStreetHousenumber = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label="Straße / Nr." colspans={DREI_ZU_ZWEI_COLSPAN}>
            {renderStreet(props)}
            {renderHousenumber(props)}
        </FormRow>);
};

const renderStreet = (props: PersonalDataProps): JSX.Element => {
    const maxLengthStreet = 51;
    return (<Field
        name="adresse.strasse"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field) || (props.spcsAddressError && streetErrorMessage)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_STREET}
                    maxLength={maxLengthStreet}
                    placeholder="Straße"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: event.target.value,
                                hausnummer: (props.inputData.adresse && props.inputData.adresse.hausnummer) ? props.inputData.adresse.hausnummer : '',
                                plz: (props.inputData.adresse && props.inputData.adresse.plz) ? props.inputData.adresse.plz : '',
                                ort: (props.inputData.adresse && props.inputData.adresse.ort) ? props.inputData.adresse.ort : ''
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderHousenumber = (props: PersonalDataProps): JSX.Element => {
    const maxLengthHousenumber = 10;
    return (<Field
        name="adresse.hausnummer"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field) || (props.spcsAddressError && housenumberErrorMessage)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_HOUSENUMBER}
                    maxLength={maxLengthHousenumber}
                    placeholder="Hausnummer"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: event.target.value,
                                plz: props.inputData.adresse ? props.inputData.adresse.plz : '',
                                ort: props.inputData.adresse ? props.inputData.adresse.ort : ''
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderPostalCity = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label="PLZ / Ort" colspans={ZWEI_ZU_DREI_COLSPAN}>
            {renderPostalCode(props)}
            {renderCity(props)}
        </FormRow>);
};

const renderPostalCode = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPostalCode = 5;
    return (<Field
        name="adresse.plz"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field) || (props.spcsAddressError && postalCodeErrorMessage) }>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_POSTALCODE}
                    maxLength={maxLengthPostalCode}
                    placeholder="PLZ"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: props.inputData.adresse ? props.inputData.adresse.hausnummer : '',
                                plz: event.target.value,
                                ort: props.inputData.adresse ? props.inputData.adresse.ort : ''
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderCity = (props: PersonalDataProps): JSX.Element => {
    const maxLengthCity = 30;
    return (<Field
        name="adresse.ort"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field) || (props.spcsAddressError && cityErrorMessage)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_CITY}
                    maxLength={maxLengthCity}
                    placeholder="Ort"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: props.inputData.adresse ? props.inputData.adresse.hausnummer : '',
                                plz: props.inputData.adresse ? props.inputData.adresse.plz : '',
                                ort: event.target.value
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderEmail = (props: PersonalDataProps): JSX.Element => {
    const maxLengthEmail = 60;
    return (<Field
        name="email"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label={<span>E-Mail</span>}
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_EMAIL}
                maxLength={maxLengthEmail}
                placeholder="E-Mail"
                onBlur={() => setFieldTouched(field.name)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        email: event.target.value
                    });
                }}
                value={field.value}
                error={(props.spcsEmailError && emailErrorMessage) || getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderTelephoneNumber = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label={<span>Telefon</span>} colspans={ZWEI_ZU_DREI_COLSPAN}>
            {renderPrefix(props)}
            {renderPhonenumber(props)}
        </FormRow>);
};

const renderPrefix = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPrefix = 6;
    return (<Field
        name="vorwahl"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={(props.spcsPhoneError && phonenumberErrorMessageTwo) || getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_PREFIX}
                    maxLength={maxLengthPrefix}
                    placeholder="Vorwahl"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            vorwahl: event.target.value
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderPhonenumber = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPhonenumber = 12;
    return (<Field
        name="rufnummer"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={(props.spcsPhoneError && phonenumberErrorMessage) || getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_PHONENUMBER}
                    maxLength={maxLengthPhonenumber}
                    placeholder="Rufnummer"
                    onBlur={() => setFieldTouched(field.name)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            rufnummer: event.target.value
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

export default PersonalData;
